<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('status')">
                            <b-form-select v-model="status"
                                           :options="statusOptions"
                                           :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <div v-show="status=='declined'">
                        <ValidationProvider name="explanation" rules="required_if:status,declined" v-slot="{valid, errors}">
                            <b-form-group :label="$t('explanation')">
                                <b-form-textarea v-model="explanation"
                                                 :state="errors[0] ? false : null"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    <b-button variant="primary" class="mt-0" @click="update">
                        {{ $t('save') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import UndergraduateTransferService from '@/services/UndergraduateTransferService'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formData: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                status: null,
                explanation: null,
                statusOptions: [
                    {value: null, text: '-'},
                    {value: 'approved', text: this.$t('approved')},
                    {value: 'declined', text: this.$t('declined')}
                ],
                prepSchoolOptions: [
                    {value: null, text: this.$t('select')},
                    {value: 1, text: this.$t('yes')},
                    {value: 0, text: this.$t('no')}
                ]
            }
        },
        methods: {
            update() {
                if(this.checkPermission('undergraduatetransfer_approveforprepschool')){
                    let formData = {
                        undergraduate_transfer_id: this.formId,
                        status: this.status,
                        explanation: this.explanation
                    }
                    if (formData.status == 'approved') {
                        formData.explanation = null;
                    }

                    UndergraduateTransferService.prepSchoolApprove(formData)
                                                .then(response => {
                                                    this.$emit('updated');
                                                })
                                                .catch((e) => {
                                                    this.showErrors(e)
                                                })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
        }
    }
</script>
